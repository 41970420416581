@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Kanit", sans-serif;
}
header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -9;
    background-color: black;
    padding: 20px;
}

.undi{
    width: 20%;
}

.logo{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.logo img{
    width: 50%;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
}


@keyframes rotate {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   }
   

@media (max-width: 768px) {
    header{
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

    .logo{
        width: 40%;
    }

    .undi{
        display: none;
    }
}