.mainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    gap: 20px;
    background-color: black;
    padding: 60px 80px;
    padding-top: 20px;
}

#box1 {
    background-image: url('../Images/box6.jpg');
}

#box2 {
    background-image: url('../Images/box2.jpg');
}

#box3 {
    background-image: url('../Images/box1.jpg');
}

#box4 {
    background-image: url('../Images/box5.jpg');
}

#box5 {
    background-image: url('../Images/box4.jpg');
}

#box6 {
    background-image: url('../Images/box7.jpg');
}

#box7 {
    background-image: url('../Images/box3.jpg');
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    gap: 30px;
text-align: center;
}



.gallery {
    width: 100%;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 300px;
    padding-top: 20px;
}

.box {
    background-size: cover;
    background-position: top;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    background-blend-mode: hard-light;
    cursor: pointer;
    transition: all 1s ease-in-out;
}

.box:hover {
    background-color: #999;
    background-position: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.row-2 {
    grid-row: span 2;
}

.col-2 {
    grid-column: span 2;
}


@media (max-width: 768px) {
    .mainContainer{
        padding: 40px 20px;
    }
    .container{
        padding: 0;
        gap: 10px;
        padding: 20px;
    }

    .gallery{
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .box{
        height: 300px;
    }

}